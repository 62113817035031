import React, { forwardRef } from "react";
import { FaInfoCircle } from "react-icons/fa";
import useBoolean from "./useBoolean";

export const InfoBubble = ({
    hiddenButtons = [],
    show = false,
    toggle,
    targetId,
    as,
    hideBubble,
    buttonLabels,
    trigger,
    backgroundColor
}) => {
    const Bubble = as;
    return (
        <Bubble
            hide={hideBubble}
            show={show}
            toggle={toggle}
            backgroundColor={backgroundColor}
            trigger={trigger}
            targetId={targetId}
        >
            <article className="info-bubble">
                <h4>The Action Buttons</h4>
                <ul>
                    <li>
                        <strong>{buttonLabels.submit || "Submit"}</strong> validates &amp; forwards your form data.
                    </li>
                    {!hiddenButtons.includes("revert") && (
                        <li>
                            <strong>{buttonLabels.revert || "Revert"}</strong> returns the form to its starting point.
                        </li>
                    )}
                    {!hiddenButtons.includes("reset") && (
                        <li>
                            <strong>{buttonLabels.reset || "Reset"}</strong> clears everything so you can start again.
                        </li>
                    )}
                </ul>
            </article>
        </Bubble>
    );
};

const Trigger = forwardRef(({ id, toggle }, triggerRef) => (
    <span ref={triggerRef}>
        <FaInfoCircle color="#00f" className="info-trigger" id={id} onClick={toggle} />
    </span>
));

export const InfoBubbleTrigger = ({ id = "info-bubble", helpers }) => {
    const { options, widgets } = helpers;
    const [show, { toggle, setFalse: hideBubble }] = useBoolean(false);
    const { buttons: { submit, revert, reset, hide = [] } = {} } = options;
    return hide.length >= 2 ? null : (
        <InfoBubble
            hiddenButtons={hide}
            show={show}
            toggle={toggle}
            as={widgets.bubble}
            trigger={<Trigger id={id} toggle={toggle} />}
            targetId={id}
            hide={hideBubble}
            buttonLabels={{ submit, reset, revert }}
            backgroundColor="#f8f8f8"
        />
    );
};
