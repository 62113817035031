import React, { useRef } from "react";
import Label from "./Label";

const File = ({
    name,
    title,
    label = "File upload",
    description,
    inputType,
    dataType,
    rows,
    twelfths,
    hidden,
    disabled,
    readOnly,
    control: component,
    required,
    helpers,
    accept,
    multiple,
    rules,
    onBlur,
    onFocus,
    onChange,
    value,
    error,
    isTouched,
    isInvalid,
    outerStyles = {},
    innerStyles = {}
}) => {
    const ref = useRef();
    return (
        <div className="field-group" style={outerStyles}>
            <Label htmlFor="file" required={required} helpers={helpers}>
                {title}
            </Label>
            <input
                id={name}
                type="file"
                accept={accept}
                multiple={multiple}
                className="form-control"
                style={innerStyles}
                required={required}
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={() => onChange(ref.current.files)}
                ref={ref}
            />
        </div>
    );
};

export default File;
