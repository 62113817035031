import dotObj from "dot-object";
import {
    generateRandomString,
    getSymbols,
    isExpression,
    getDynamicItems,
    makeToken,
    parseFieldPath,
    extractTokenSymbolsAndEmbeddedExpressions
} from "./compilerUtils.js";
import { dedupeArray } from "./utils.js";

const extractDynamics = (obj, { debug = false } = {}) => {
    const watchlist = [];
    const expressionsList = [];

    const attrDict = dotObj.dot(obj); // start with object flattened into dot-path (attributePath/attributeSpec) key/value pairs
    const attrTuples = Object.entries(attrDict);
    const arraySections = attrTuples
        .filter(([key, val]) => key.endsWith(".type") && val === "array")
        .map(([key]) => key.slice(0, -5));
    const arraySectionsFieldCollections = arraySections.map(x => x + ".fields");
    debug && console.log("Array Sections:", arraySections);
    const arraySectionFields = Array.from(
        new Set(
            arraySectionsFieldCollections
                .map(x => {
                    const fieldPaths = attrTuples.filter(([fieldPath]) => fieldPath.startsWith(x + "."));
                    return fieldPaths.map(([fieldPath]) => fieldPath.slice(0, fieldPath.lastIndexOf(".")));
                })
                .flat()
        )
    );
    debug && console.log("Array Sections:", arraySections, arraySectionFields);

    arraySections.forEach(sect => {
        attrDict[sect + ".isArraySection"] = true;
    });

    arraySectionFields.forEach(fld => {
        attrDict[fld + ".isArrayField"] = true;
    });

    const dynamicItems = getDynamicItems(attrTuples); // filter it down to just the dynamic items

    const dynamicAttributeSpecs = dynamicItems.map(([path, spec]) => {
        // from any embedded tokens, we get any embedded symbols (variables) and mathJs expressions
        // e.g. "=address.count>1", "This is ${whatItIs}", "Your name is ${=equalText(name.firstName, '') ? 'unknown' : name.firstName}"
        const { symbols, expressions } = extractTokenSymbolsAndEmbeddedExpressions(spec);

        const tokenExprSymbols = expressions.map(expr => getSymbols(expr)).flat(); // get any further symbols (vars) used in expressions
        const expressionKeys = expressions.map(expr => [expr, generateRandomString()]); // allocate random IDs to all expressions

        let newSpec = spec,
            rawSpec = undefined;
        const predicateExpressions = expressionKeys.map(([expr, key]) => {
            // make a list of expressions inside tokens
            newSpec = newSpec.replace(makeToken(expr), makeToken(key)); // replace all expressions with tokens, mutating as we go

            return { key, expr, dependsOn: getSymbols(expr), path };
        });

        // now we turn to specs that are entirely expressions, i.e. begin with "=address.count>1"
        const isSpecExpression = isExpression(spec);
        const expressionSymbols = isSpecExpression ? getSymbols(spec) : [];

        if (isSpecExpression) {
            const key = generateRandomString(),
                expr = spec;
            newSpec = makeToken(key);
            rawSpec = key;
            expressionsList.push({ key, expr, dependsOn: expressionSymbols, path });
        }

        const allExpressionSymbols = [...tokenExprSymbols, ...expressionSymbols]; // combine both lists of expression symbols
        const allSymbols = [...symbols, ...allExpressionSymbols]; // make one big list of symbols

        watchlist.push(...allSymbols);
        expressionsList.push(...predicateExpressions);

        const [fieldPath, attr] = parseFieldPath(path);

        return { path, spec: newSpec, key: rawSpec, fieldPath, attr, dependsOn: allSymbols };
    });

    for (const dynamicItem of dynamicAttributeSpecs) {
        // replace dynamic attribute specs in formDef with processed ones
        const { path, spec } = dynamicItem;
        attrDict[path] = spec;
    }

    return {
        watchlist: dedupeArray(watchlist),
        expressionsList,
        dynamicAttributeSpecs,
        formDef: dotObj.object(attrDict)
    };
};

export default extractDynamics;
