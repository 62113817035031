import React, { forwardRef } from "react";
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import useBoolean from "./useBoolean";

const DEFAULT_SIZE = "2.5em";

const Trigger = forwardRef(({ size, id, toggle }, triggerRef) => (
    <span ref={triggerRef} className="form-health invalid">
        <FaExclamationTriangle
            size={size}
            color="#fc0"
            style={{ strokeWidth: 10, stroke: "black" }}
            id={id}
            onClick={toggle}
        />
    </span>
));

export const FormHealthIndicator = ({
    helpers: { widgets = {} },
    id = "form-health",
    isError = false,
    size = DEFAULT_SIZE,
    children = null
}) => {
    const [bubbleVisible, { toggle, setFalse: hideBubble }] = useBoolean(false);

    return isError ? (
        <widgets.bubble
            hide={hideBubble}
            show={bubbleVisible}
            toggle={toggle}
            trigger={<Trigger size={size} id={id} toggle={toggle} />}
            targetId={id}
            backgroundColor="#f8f8f8"
        >
            {children}
        </widgets.bubble>
    ) : (
        <FaCheckCircle size={size} color="green" className="form-health valid" id={id} />
    );
};
