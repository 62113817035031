import React from "react";
import is from "@sindresorhus/is";

export const SectionHeader = ({ header, title: titleProp, description: descProp, helpers = {} }) => {
    const isMultipartHeader = is.object(header),
        { title = titleProp, description = descProp } = isMultipartHeader ? header : {},
        { widgets } = helpers;
    return header || title || description ? (
        <header className="header">
            {title || description ? (
                <widgets.text className="text-outer">
                    {title && (
                        <h2 className="title">
                            <widgets.smartText text={title} helpers={helpers} />
                        </h2>
                    )}
                    {description && (
                        <h4 className="description">
                            <widgets.smartText text={description} helpers={helpers} />
                        </h4>
                    )}
                </widgets.text>
            ) : !isMultipartHeader ? (
                <widgets.smartText text={header} helpers={helpers} />
            ) : null}
        </header>
    ) : null;
};
