import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";

const PathSeparator = () => (
    <span className="path-separator">
        <FaLongArrowAltRight />
    </span>
);

export const ErrorLink = ({ fieldName, fieldPath, pathLabels, error, errorColor }) => {
    // log("Error link", error, parent, parentPath, label, fld);
    return (
        <li className="error-link" key={fieldName}>
            <a href={`#${fieldPath}`}>
                <span className="path-indicator">
                    {pathLabels.map((label, i, ary) => (
                        <span key={fieldPath + label}>
                            {i > 0 && <PathSeparator />}
                            {label}
                        </span>
                    ))}
                </span>
                {error}
            </a>
        </li>
    );
};
