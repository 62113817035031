import React, { useState } from "react";
import "./Typeahead.scss";
import is from "@sindresorhus/is";
import { useCombobox } from "downshift";
import Label from "./Label";
import { classNames } from "./utils";

const Typeahead = ({ name, value, options, title, required, hidden, twelfths, helpers }) => {
    const [inputItems, setInputItems] = useState(options);
    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        selectItem,
        autofocus = false
    } = useCombobox({
        items: inputItems,
        itemToString: x => x?.text || "",
        onInputValueChange: ({ inputValue }) => {
            setInputItems(
                options.filter(x => {
                    const val = inputValue.toLowerCase();
                    return x.text.toLowerCase().includes(val);
                })
            );
        }
    });

    const styles = {
        gridColumn: "span " + (twelfths || 4),
        display: hidden ? "none" : undefined
    };

    return (
        <>
            <div className="field-group" style={styles}>
                <Label {...getLabelProps()} required={required} helpers={helpers}>
                    {title}
                </Label>
                <div className="typeahead" {...getComboboxProps()}>
                    <input
                        className="input"
                        helpers={helpers}
                        {...getInputProps({
                            onFocus: () => {
                                if (!isOpen) {
                                    openMenu();
                                }
                            }
                        })}
                        autoFocus={autofocus}
                    />
                    <button
                        className="clear"
                        tabIndex={-1}
                        onClick={() => {
                            selectItem(null);
                        }}
                        aria-label="clear selection"
                    >
                        &#215;
                    </button>
                    <button className="open-close" type="button" {...getToggleButtonProps()} aria-label="toggle menu">
                        {isOpen ? "\u25B2" : "\u25BC"}
                    </button>
                </div>
            </div>
            <ul {...getMenuProps()} className={classNames(["list", isOpen ? "open" : ""])}>
                {isOpen &&
                    inputItems.map(({ key, text }, index) => (
                        <li
                            style={highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}}
                            key={key}
                            {...getItemProps({ index })}
                        >
                            {text}
                        </li>
                    ))}
            </ul>
        </>
    );
};

export default Typeahead;
