import React, { useMemo } from "react";
import is from "@sindresorhus/is";
import { Form } from "react-bootstrap";
import { classNames } from "@jfdi/formulationist-core";
import Feedback from "./Feedback";
import { processOptions } from "./utils";

const Radio = ({
    value,
    name,
    dataType,
    label,
    required,
    description,
    options,
    inline = false,
    disabled,
    imageSize,
    helpers,
    isTouched,
    isInvalid,
    error,
    onChange,
    onBlur,
    onFocus,
    autofocus = false
}) => {
    const opts = useMemo(() => processOptions(options, imageSize), [imageSize, options]);
    const hasImages = opts[0]?.imageSrc;
    return (
        <Form.Group name={name} className="field-group radio">
            <Form.Label name={name + "_L"}>{label}</Form.Label>
            <Form.Group
                name={name}
                className={classNames(["field-group", isInvalid ? "is-invalid" : ""])}
                aria-labelledby={name + "_L"}
            >
                {options.filter(Boolean).map((x, i) => {
                    const option = is.object(x) ? x : { key: x, text: x };
                    const { key, text } = option;
                    return (
                        <Form.Check
                            label={text}
                            type="radio"
                            disabled={disabled}
                            inline={inline}
                            checked={x === value}
                            name={`${name}${i}`}
                            key={key}
                            className="radio"
                            value={key}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onClick={e => onChange(key)}
                            autoFocus={autofocus}
                        />
                    );
                })}
            </Form.Group>
            {isTouched && isInvalid && <Feedback error={error} fieldPath={name} title={label} />}
        </Form.Group>
    );
};

export default Radio;
