import React from "react";
import "./Likert.scss";
import Likert from "react-likert-scale";

const defaultResponses = [
    { key: 1, text: "Terrible" },
    { key: 2, text: "Not great" },
    { key: 3, text: "Average" },
    { key: 4, text: "Good" },
    { key: 5, text: "Excellent" }
];

const FCLikert = ({
    title,
    name,
    value,
    options = defaultResponses,
    description,
    onBlur,
    onFocus,
    onChange,
    isTouched,
    disabled,
    required,
    isInvalid,
    error,
    helpers
}) => {
    const responses = options.map(({ key, text }) => ({ value: key, text, checked: key === value }));
    const { widgets } = helpers;

    return (
        <div className="field-group">
            <widgets.label required={required} helpers={helpers}>
                {title}
            </widgets.label>
            <Likert responses={responses} onChange={({ value }) => onChange(value)} id={name} />
        </div>
    );
};

export default FCLikert;
