import React, { useMemo } from "react";
import { SubSection, findFirstField } from "@jfdi/formulationist-core-form-common";
import { useFormRuntime } from "@jfdi/formulationist-core";
import { FormFooter } from "./FormFooter";
import is from "@sindresorhus/is";

export const FormSinglePage = ({
    compiledForm,
    onSubmit,
    onReset,
    onRevert,
    onChange,
    lookups,
    parameterData,
    footerComponent
}) => {
    const { form, errors, helpers, handleSubmit, submitCount, isSubmitting, isDirty, reset, revert, isLoading } =
        useFormRuntime({
            compiledForm,
            onReset,
            onRevert,
            onChange,
            lookups,
            parameterData
        });

    const FooterComponent = footerComponent;
    // log("Form Errors", errors);
    const firstField = useMemo(() => findFirstField(form.fields), [form.fields]);
    helpers.firstField = firstField;

    return isLoading ? null : (
        <form noValidate onSubmit={() => false} className="formulationist single">
            <section className="section-container level0">
                <SubSection sectionId={null} sectionDef={form} level={0} key="root" errors={errors} helpers={helpers} />
            </section>
            {FooterComponent && <FooterComponent isDirty={isDirty} isValid={is.emptyObject(errors)} />}
            <FormFooter
                errors={errors}
                helpers={helpers}
                isSubmitting={isSubmitting}
                isDirty={isDirty}
                onSubmit={handleSubmit(onSubmit)}
                onReset={reset}
                onRevert={revert}
            />
        </form>
    );
};
// FormSinglePage.whyDidYouRender = { logOnDifferentValues: true };
