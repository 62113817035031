import { useState } from "react";

const useBoolean = (initial = false) => {
    const [boolean, setBoolean] = useState(initial);
    const setFalse = () => setBoolean(false),
        setTrue = () => setBoolean(true),
        toggle = () => setBoolean(x => !x);
    return [boolean, { setBoolean, setFalse, setTrue, toggle }];
};

export default useBoolean;
