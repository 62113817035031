import is from "@sindresorhus/is";
import { t2o, o2t, dedupeArray, getType, isSection, dotJoin, isValidation, ensureArray } from "./utils";
import cloneDeep from "lodash/cloneDeep";

let validatedFields = [],
    arraySections = {},
    defaultValues = {},
    fieldDefs = {};

const extractValidations = (fieldDef, fieldPath) => {
    return o2t(fieldDef).reduce((validations, [attr, rawArgs]) => {
        const args = is.boolean(rawArgs) ? [] : ensureArray(rawArgs);
        if (isValidation(attr)) {
            // console.log("ATTR:", fieldName, attr, args);
            validations.push([attr, args]);
        }
        return validations;
    }, []);
};

const naturalDefaults = {
    string: "",
    number: null,
    date: new Date(0),
    boolean: undefined,
    array: [],
    object: {}
};
const getNaturalDefault = type => naturalDefaults[type];

function processFields({ sectionFields, sectionId, level, naturalDefaults, pages, isArraySection }) {
    return t2o(
        o2t(sectionFields).map(([fieldName, fieldDef]) => {
            const fieldPath = dotJoin(sectionId, fieldName),
                type = getType(fieldDef);

            fieldDefs[fieldPath] = fieldDef;

            if (is.object(fieldDef)) {
                if (("default" in fieldDef) & !isArraySection) defaultValues[fieldPath] = fieldDef.default;

                const validations = extractValidations(fieldDef, fieldPath);
                if (is.nonEmptyArray(validations)) validatedFields.push(fieldPath);
                if (isSection(fieldDef)) {
                    const [sctFields, sctNatDflts] = processSection(fieldName, fieldDef, level + 1);
                    if (type === "array") {
                        arraySections[fieldPath] = t2o(
                            sctNatDflts.map(([fld, val]) => [fld.slice(fieldPath.length + 1), val])
                        ); // natural default vals for array sections get stored separately
                    } else {
                        naturalDefaults.push(...sctNatDflts);
                    }
                    if (level === 1) pages.push(fieldName); // record level 1 sections as "pages" for multipage forms
                    return [fieldName, { fieldPath, ...sctFields, validations }];
                } else {
                    // plain fields
                    const fldInitData = [fieldPath, getNaturalDefault(type)];
                    naturalDefaults.push(fldInitData);
                    return [
                        fieldName,
                        {
                            fieldPath,
                            fieldName,
                            ...fieldDef,
                            validations
                        }
                    ];
                }
            } else {
                console.warn("Unknown field definition", fieldDef);
                return null;
            }
        })
    );
}
// This maps the form fields to an array of fieldDef objects with their validations.
// It also compiles a data object (with natural default values), and a quickref list of field defaults & calculated fields.
function processSection(sectionId, sectionDef, level = 1) {
    const naturalDefaults = [],
        pages = [];
    const {
        fields: sectionFields = {},
        header,
        title,
        description,
        label,
        labelSingular,
        twelfths,
        when,
        min,
        max,
        type = "object",
        tabs
    } = sectionDef;

    const fields = processFields({
        sectionFields,
        sectionId,
        level,
        naturalDefaults,
        fieldDefs,
        pages,
        isArraySection: type === "array"
    });

    const rebuiltSectionDef = {
        fieldName: sectionId,
        header,
        title,
        label,
        labelSingular,
        description,
        twelfths,
        level,
        type,
        when,
        min,
        max,
        fields,
        tabs
    };

    return [rebuiltSectionDef, naturalDefaults, pages];
}

const extractMetadata = (formDef, { debug = false } = {}) => {
    validatedFields = [];
    arraySections = {};
    defaultValues = {};
    const [sectionDef, naturalDefaults, pages] = processSection(null, formDef, 1);
    return [
        sectionDef,
        t2o(naturalDefaults),
        defaultValues,
        pages,
        dedupeArray(validatedFields),
        cloneDeep(arraySections),
        fieldDefs
    ];
};

export default extractMetadata;
