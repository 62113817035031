import React, { createContext, useContext } from "react";

const nullFn = () => null;
const consoleNull = Object.fromEntries(
    Object.entries(console).map(([k, v]) => (typeof v === "function" ? [k, nullFn] : [k, v]))
);

const DebugLogging = createContext();

export const useDebugLogging = () => useContext(DebugLogging);

export const LoggingProvider = ({ children, debugMode = false }) => (
    <DebugLogging.Provider value={debugMode ? console : consoleNull}>{children}</DebugLogging.Provider>
);
