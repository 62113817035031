import { useRef } from "react";

export default function useVisited() {
    const visited = useRef([]);

    const wasVisited = page => visited.current.includes(page);
    const setVisited = page => {
        if (!wasVisited(page)) visited.current.push(page);
    };

    return { wasVisited, setVisited };
}
