import { parse } from "mathjs";
import is from "@sindresorhus/is";
import { dedupeArray } from "./utils";

export const regexDollarBraceToken = /\$\{([^}]*)\}/gm;
export const regexExpression = /^=/;
export const isExpression = x => regexExpression.test(x);
export const isNotExpression = x => !isExpression(x);
export const hasToken = x => regexDollarBraceToken.test(x);
export const isDynamic = x => isExpression(x) || hasToken(x);
export const tupleizeFn =
    fn =>
    ([, val]) =>
        fn(val);
export const isTupleDynamic = tupleizeFn(isDynamic);
export const isTupleValExpression = tupleizeFn(isExpression);
export const isTupleValNotExpression = t => !isTupleValExpression(t);
export const stripIndices = x => /^(\w+)/g.exec(x)[0];
export const parseFieldPath = path => {
    const parts = path.split("."),
        attr = stripIndices(parts.pop()),
        fieldPath = parts.filter(x => x !== "fields").join(".");
    return [fieldPath, attr];
};
export const generateRandomString = (length = 6) => Math.random().toString(36).substr(2, length).toUpperCase();
export const makeToken = x => "${" + x + "}";

const isSymbol = (node, path, parent) =>
    (node?.isAccessorNode && !parent?.isAccessorNode) || (node?.isSymbolNode && (!path || path?.startsWith("args")));

const isFormula = x => x && is.string(x) && x.startsWith("=");
const sanitizeFormula = expr => (isFormula(expr) ? expr.slice(1) : expr);
export const getSymbols = expr => {
    const tree = parse(sanitizeFormula(expr));
    // console.log("Tree:", tree);
    const symbolNodes = tree.filter(isSymbol);
    const vars = symbolNodes.map(node => node.toString());
    return dedupeArray(vars);
};

const extractTokens = spec => {
    const allTokens = spec.matchAll(regexDollarBraceToken);
    return Array.from(allTokens);
};

export const getDynamicItems = attrTuples =>
    attrTuples.filter(isTupleDynamic); /* filter it down to just the dynamic items*/

export const extractTokenSymbolsAndEmbeddedExpressions = spec => {
    const allTokensAry = extractTokens(spec); // tokens are of the form ${token}

    return allTokensAry.reduce(
        (o, [, token]) => {
            (isExpression(token) ? o.expressions : o.symbols).push(token);
            return o;
        },
        { symbols: [], expressions: [] }
    );
};
