import "./bs5.scss";
import React from "react";
import { Container as container, FormControl, FormLabel } from "react-bootstrap";
import { widgets as baseWidgets } from "@jfdi/formulationist-widgets-base";

import checkbox from "./Checkbox";
import feedback from "./Feedback";
import input from "./Input";
import radio from "./Radio";
import select from "./Select";
import bubble from "./PopoverBubble";
import button from "./Button";

export const id = "bs5";

export const widgets = {
    ...baseWidgets,
    feedback,
    checkbox,
    radio,
    select,
    input,
    button,
    container,
    text: "div",
    bubble,
    label: FormLabel,
    baseInput: FormControl
};

export const provider = React.Fragment,
    init = () => null,
    colors = { themePrimary: "#0078d4", themeDarker: "#106ebe", themeLighter: "#71afe5", errorColor: "#f55" };
