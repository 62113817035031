import React, { useMemo } from "react";
import is from "@sindresorhus/is";
import Feedback from "./Feedback";
import Label from "./Label";
import { classNames } from "@jfdi/formulationist-core";
import { MultiSelect } from "react-multi-select-component";

const Multi = ({
    title,
    name,
    value = [],
    options,
    description,
    onBlur,
    onFocus,
    onChange,
    isTouched,
    disabled,
    required,
    isInvalid,
    error,
    helpers,
    config,
    autofocus = false
}) => {
    const opts = useMemo(() => options.map(({ key, text }) => ({ label: text, value: key })), [options]);
    return (
        <div className={classNames(["field-group", "select", isInvalid ? "invalid" : "valid"])}>
            <Label htmlFor={name} helpers={helpers}>
                {title}
            </Label>
            <MultiSelect
                id={name}
                aria-label={description}
                disabled={disabled}
                options={opts}
                value={value}
                onChange={onChange}
                error={error}
                isOpen={config.open}
                autoFocus={autofocus}
                disableSearch={!config.search}
            />
            {isTouched && isInvalid && <Feedback error={error} fieldPath={name} title={title} />}
        </div>
    );
};

export default Multi;
