import React from "react";
import { classNames, isSection, o2t, ensureArray } from "./lib/utils";
import { FormulationistField } from "./FormulationistField";
import { SubSection } from "./SubSection";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useDebugLogging } from "@jfdi/formulationist-core";

const allSections = flds => flds.every(([, fldDef]) => isSection(fldDef));

export const FormFields = ({ fields = {}, level, tabs = false, errors = {}, helpers }) => {
    const { log } = useDebugLogging();
    const { getValues, getError, hasBeenTouched, widgets, firstField } = helpers;
    log("AutoFocus First Field", firstField);

    if (fields) {
        const fieldTuples = o2t(fields);
        if (tabs && allSections(fieldTuples)) {
            const { tabHeaders, panels } = fieldTuples.reduce(
                (o, [, fieldDef]) => {
                    const { fieldPath, fieldName, when: isVisible = true, title } = fieldDef;
                    // log("Section?", fieldPath, isSection(fieldDef), visible);
                    if (isVisible) {
                        // const value = getValues(fieldPath),
                        //     error = ensureArray(getError(fieldPath))[0],
                        //     isTouched = hasBeenTouched(fieldPath);
                        log("TAB", fieldPath, title);
                        o.tabHeaders.push(
                            <Tab key={fieldPath}>
                                <widgets.label helpers={helpers}>{title}</widgets.label>
                            </Tab>
                        );
                        o.panels.push(
                            <TabPanel key={fieldPath}>
                                <SubSection
                                    sectionId={fieldPath}
                                    sectionDef={fieldDef}
                                    level={level + 1}
                                    key={fieldPath}
                                    errors={errors}
                                    helpers={helpers}
                                />
                            </TabPanel>
                        );
                    }
                    return o;
                },
                { tabHeaders: [], panels: [] }
            );

            return (
                <Tabs>
                    <TabList>{tabHeaders}</TabList>
                    {panels}
                </Tabs>
            );
        } else {
            // log("GenFields", fields);
            const fieldElements = fieldTuples.map(([, fieldDef], index) => {
                const { fieldPath, fieldName, when: isVisible = true } = fieldDef;
                // log("Section?", fieldPath, isSection(fieldDef), visible);
                if (isVisible) {
                    const value = getValues(fieldPath),
                        error = ensureArray(getError(fieldPath))[0],
                        isTouched = hasBeenTouched(fieldPath);

                    if (isSection(fieldDef)) {
                        return (
                            <SubSection
                                sectionId={fieldPath}
                                sectionDef={fieldDef}
                                level={level + 1}
                                key={fieldPath}
                                errors={errors}
                                helpers={helpers}
                            />
                        );
                    } else {
                        const autofocus = fieldPath === firstField;
                        autofocus && log("AutoFocus", fieldPath);
                        return (
                            <FormulationistField
                                fieldPath={fieldPath}
                                error={error}
                                fieldName={fieldName}
                                fieldDef={fieldDef}
                                key={fieldPath}
                                helpers={helpers}
                                value={value}
                                isTouched={isTouched}
                                autofocus={autofocus}
                            />
                        );
                    }
                } else return null;
            });
            return <section className={classNames(["form-grid", `level${level}`])}>{fieldElements}</section>;
        }
    } else return null;
};
