import React from "react";
import is from "@sindresorhus/is";
import { FormHealthIndicator, InfoBubbleTrigger, ErrorList } from "@jfdi/formulationist-core-form-common";

const DefaultLabels = { submit: "Submit", revert: "Revert", reset: "Reset" };
export const FormFooter = ({ onSubmit, onRevert, onReset, errors, isSubmitting, isDirty, helpers }) => {
    const { widgets, options } = helpers,
        { buttons: { submit, revert, reset, hide = [] } = {} } = options,
        hasErrors = is.nonEmptyObject(errors),
        showErrors = hasErrors,
        shouldShow = x => !hide.includes(x);
    return (
        <>
            <footer id="buttons">
                <widgets.button
                    id="submit"
                    disabled={!isDirty || isSubmitting || hasErrors}
                    type="submit"
                    onClick={onSubmit}
                >
                    {submit || DefaultLabels.submit}
                </widgets.button>
                {shouldShow("revert") && (
                    <widgets.button id="revert" disabled={isSubmitting} type="button" onClick={onRevert}>
                        {revert || DefaultLabels.revert}
                    </widgets.button>
                )}
                {shouldShow("reset") && (
                    <widgets.button id="reset" disabled={isSubmitting} type="button" onClick={onReset}>
                        {reset || DefaultLabels.reset}
                    </widgets.button>
                )}
                <InfoBubbleTrigger helpers={helpers} />
                <FormHealthIndicator id="form-health" isError={hasErrors} helpers={helpers}>
                    {showErrors && <ErrorList.Container errors={errors} helpers={helpers} />}
                </FormHealthIndicator>
            </footer>
        </>
    );
};
