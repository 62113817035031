import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import Feedback from "./Feedback";

const Input = ({
    name,
    label,
    description,
    inputType,
    dataType,
    rows,
    twelfths,
    hidden,
    disabled,
    readOnly,
    control: component,
    required,
    helpers,
    uppercase = false,
    prefix,
    suffix,
    min,
    max,
    onBlur,
    onFocus,
    onChange,
    value,
    stringValue,
    error,
    isTouched,
    isInvalid,
    autofocus = false
}) => {
    // log("Input:", name, value, isTouched, isInvalid, error);
    return (
        <Form.Group className="field-group input" id={name + "_group"}>
            <Form.Label htmlFor={name}>{label}</Form.Label>
            <InputGroup hasValidation>
                {prefix && <InputGroup.Text>{prefix}</InputGroup.Text>}
                <Form.Control
                    disabled={disabled}
                    isInvalid={isTouched && isInvalid}
                    isValid={isTouched && !isInvalid}
                    error={isTouched && isInvalid ? error : null}
                    id={name}
                    type={inputType}
                    as={component}
                    rows={rows}
                    label={label}
                    className="form-control"
                    style={{
                        gridColumn: "span " + (twelfths || 4),
                        display: hidden ? "none" : null
                    }}
                    value={stringValue ?? value}
                    onChange={e =>
                        onChange(
                            dataType === "string"
                                ? uppercase
                                    ? (e.currentTarget.value ?? "").toUpperCase()
                                    : e.currentTarget.value
                                : dataType === "number"
                                ? Number(e.currentTarget.value)
                                : e.currentTarget.value
                        )
                    }
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoFocus={autofocus}
                />
                {suffix && <InputGroup.Text>{suffix}</InputGroup.Text>}
                <Feedback error={error} />
            </InputGroup>
            {/* {isTouched && isInvalid && <Feedback error={error} />} */}
        </Form.Group>
    );
};

export default Input;
