import React, { useCallback, useEffect, useState } from "react";
import { cap1st, classNames, ensureArray } from "./lib/utils";
import is from "@sindresorhus/is";
import { useDebugLogging } from "@jfdi/formulationist-core";

const setTime = (date, time) => {
    const d = new Date(date);
    const [hour, minute] = time.split(":");
    d.setHours(hour);
    d.setMinutes(minute);
    return d;
};

const isValidDate = value => is.date(value) && !isNaN(value.getTime());

export const FormulationistField = ({
    fieldPath,
    fieldName,
    fieldDef,
    error,
    helpers,
    value,
    isTouched,
    helpers: {
        setValue,
        setTouched,
        lookups,
        options: { defaultErrorMessages = {} }
    },
    autofocus = false
}) => {
    const { log } = useDebugLogging();
    const defaultDisabled = "calc" in fieldDef && !("disabled" in fieldDef),
        { widgets, formatISO } = helpers,
        isError = is.nonEmptyString(error),
        {
            type: dataType,
            title = cap1st(fieldName),
            description = "",
            required,
            hidden,
            twelfths,
            control: controlCfg,
            enum: optionsCfg,
            disabled = defaultDisabled,
            readOnly,
            prefix,
            suffix,
            rows,
            min,
            max,
            accept,
            multiple,
            inline
        } = fieldDef,
        label = `${title}${required ? "*" : ""}`,
        [options, setOptions] = useState(
            dataType === "boolean" && !optionsCfg
                ? [
                      { key: true, text: "Yes" },
                      { key: false, text: "No" }
                  ]
                : undefined
        ),
        [altControl, altControlCfg = {}] = ensureArray(controlCfg),
        control =
            altControl ??
            (["file", "date", "number"].includes(dataType)
                ? dataType
                : dataType === "boolean" && (!altControl || altControl === "checkbox")
                ? "checkbox"
                : dataType === "array"
                ? "multi"
                : options
                ? "select"
                : "input"),
        stringValue =
            dataType === "date" && isValidDate(value)
                ? control === "datetime-local"
                    ? formatISO(value).slice(0, 19)
                    : control === "date"
                    ? formatISO(value).slice(0, 10)
                    : control === "time"
                    ? formatISO(value).slice(11, 16)
                    : null
                : null,
        FieldComponent = widgets[control] ?? (options ? widgets.select : widgets.input),
        styles = {
            gridColumn: "span " + (twelfths || 4),
            display: hidden ? "none" : null
        };
    console.log("Field:", { value, dataType, control, stringValue, FieldComponent });

    // rules = validations2rules(validations, dataType, defaultErrorMessages, getCurrentDynamicValue);
    // log("Field", fieldPath, dataType, value, fieldDef, control, altControlCfg, optionsCfg, options);

    useEffect(() => {
        if (!options) {
            log("useEffect for Options", options, optionsCfg);

            if (is.string(optionsCfg)) {
                log("Lookups test", lookups);
                if (is.function(lookups)) {
                    const getData = async () => {
                        const data = await lookups(optionsCfg);
                        setOptions(data);
                    };
                    log("Async data");
                    getData();
                } else setOptions(lookups[optionsCfg]);
            } else if (is.array(optionsCfg)) {
                if (is.string(optionsCfg[0])) {
                    setOptions(optionsCfg.map(x => ({ text: x, key: x })));
                } else setOptions(optionsCfg);
            }
        }
    }, [dataType, log, lookups, options, optionsCfg]);

    const onChange = useCallback(
        newValue => {
            const resolvedVal =
                typeof newValue === "string"
                    ? dataType === "date"
                        ? control === "time"
                            ? setTime(value, newValue)
                            : control === "date"
                            ? new Date(`${newValue}T00:00:00`)
                            : control === "datetime-local"
                            ? new Date(newValue)
                            : newValue
                        : newValue
                    : newValue;
            console.log("onChange:", { value, newValue, resolvedVal });
            return setValue(fieldPath, resolvedVal);
        },
        [control, dataType, fieldPath, setValue, value]
    );
    const onBlur = useCallback(() => setTouched(fieldPath), [fieldPath, setTouched]);
    const onFocus = useCallback(
        () => !(is.emptyString(value) || is.nullOrUndefined(value)) && setTouched(fieldPath),
        [fieldPath, setTouched, value]
    );

    if (!is.nullOrUndefined(optionsCfg) && !options) return null;
    else
        return (
            <div className={classNames(["form-field", dataType, altControl, control, fieldPath])} style={styles}>
                <FieldComponent
                    isError={isError}
                    error={error}
                    options={options}
                    inline={inline}
                    name={fieldPath}
                    title={title}
                    label={label}
                    inputType={control}
                    dataType={dataType}
                    helpers={helpers}
                    required={required}
                    disabled={disabled}
                    readOnly={readOnly}
                    description={description}
                    prefix={prefix}
                    suffix={suffix}
                    rows={rows}
                    min={min}
                    max={max}
                    accept={accept}
                    multiple={multiple}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    isTouched={isTouched}
                    isInvalid={isError}
                    value={value}
                    stringValue={stringValue}
                    config={altControlCfg}
                    autofocus={autofocus}
                />
                <widgets.description description={description} helpers={helpers} />
            </div>
        );
};
