import { attempt } from "@jfdi/attempt";
import { create, all } from "mathjs";
import is from "@sindresorhus/is";
import t from "./time-constants.js";
import {
    getYear,
    add,
    differenceInMilliseconds,
    formatDistance,
    parseISO,
    isValid,
    format as formatdate,
    startOfToday,
    startOfDay,
    endOfDay,
    formatISO
} from "date-fns";
import { dedupeArray, getGlobalObject } from "../lib";

const msConversionFactors = {
    seconds: t.MS_PER_SECOND,
    minutes: t.MS_PER_MINUTE,
    hours: t.MS_PER_HOUR,
    days: t.MS_PER_DAY,
    weeks: t.MS_PER_WEEK,
    months: t.MS_PER_MONTH,
    years: t.MS_PER_YEAR
};

const isFormula = x => x && is.string(x) && x.startsWith("=");
const sanitizeFormula = expr => (isFormula(expr) ? expr.slice(1) : expr);
const ensurePlural = x => (x.endsWith("s") ? x : x + "s");
const twoDigits = x => (x < 10 ? "0" + x : x);
const thisMinuteNow = () => {
    const d = new Date();
    const minuteDate = `${d.getFullYear()}-${twoDigits(d.getMonth() + 1)}-${twoDigits(d.getDate())}T${twoDigits(
        d.getHours()
    )}:${twoDigits(d.getMinutes())}:00`;
    // console.log("MinuteDate", minuteDate);
    return new Date(minuteDate);
};
const parsedate = s => (is.string(s) ? parseISO(s) : s);
const dateadd = (date, number, unit) => {
    const units = ensurePlural(unit),
        start = parsedate(date),
        num = Number(number);
    const result = isValid(start) && is.number(num) ? add(start, { [units]: num }) : null;
    // console.log(`DateAdd ${start} (${typeof start}) + ${num} (${typeof num}) ${units} = ${result}`);
    return result;
};
const today = () => {
    const d = startOfToday();
    // console.log("StartOfToday", d, formatISO(d));
    return d;
};

const fns = {
    between: (x, a, b) => {
        const res = x >= a && x <= b;
        // console.log(`Is ${x} Between ${a} and ${b}?`, res);
        return res;
    },
    firstDayOfYear: (year = today().getYear()) => new Date(year, 1, 1),
    lastDayOfYear: (year = today().getYear()) => new Date(year, 12, 31),
    year: (date = today()) => getYear(parsedate(date)),
    date: (...args) => new Date(...args),
    parsedate,
    today,
    now: () => thisMinuteNow(),
    tomorrow: () => dateadd(today(), 1, "days"),
    yesterday: () => dateadd(today(), -1, "days"),
    dateadd,
    dateAdd: dateadd,
    startOfDay,
    endOfDay,
    formatdate,
    datediff: (date1, date2, units) =>
        differenceInMilliseconds(parsedate(date1), parsedate(date2)) / msConversionFactors[ensurePlural(units)],
    until: (date1, date2) => formatDistance(parsedate(date1), parsedate(date2))
};

const mathsSetup = () => {
    const math = create(all);
    math.import(fns);
    getGlobalObject().math = math;
    return math;
};

const fetchOrCreateMaths = () => {
    const cached = getGlobalObject().math;
    const math = cached ? cached : mathsSetup();
    return math;
};

export const evaluate = (expr, params = {}) => {
    const math = fetchOrCreateMaths();
    // console.log("Maths eval", expr, params);
    const [err, result] = attempt(() => math.evaluate(sanitizeFormula(expr), params));
    err && console.warn("Maths error", err.message);
    return err ? undefined : result;
};

export const parse = expr => {
    const math = fetchOrCreateMaths();
    // console.log("Maths eval", expr, params);
    const [err, result] = attempt(() => math.parse(sanitizeFormula(expr)));
    err && console.warn("Maths error", err.message);
    return err ? undefined : result;
};

const isSymbol = (node, path, parent) =>
    (node?.isAccessorNode && !parent?.isAccessorNode) || (node?.isSymbolNode && (!path || path?.startsWith("args")));

export const getSymbols = expr => {
    const math = fetchOrCreateMaths();
    const tree = math.parse(sanitizeFormula(expr));
    // console.log("Tree:", tree);
    const symbolNodes = tree.filter(isSymbol);
    const vars = symbolNodes.map(node => node.toString());
    return dedupeArray(vars);
};

export default evaluate;
