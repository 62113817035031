import is from "@sindresorhus/is";
import React from "react";
import {
    FaPlusSquare as Newrow,
    FaRegWindowClose as Removerow,
    FaChevronCircleUp as ShiftUp,
    // FaAngleDoubleUp as ShiftUp,
    FaChevronCircleDown as ShiftDown
    // FaAngleDoubleDown as ShiftDown
} from "react-icons/fa";
import { classNames, dotJoin, ensureArray, o2t } from "./lib/utils";
import { FormulationistField } from "./FormulationistField";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import { useDebugLogging } from "@jfdi/formulationist-core";

export const RepeatingSection = ({ sectionId, labelSingular, tabs, sectionFields, errors, helpers, min = 0, max }) => {
    // const { log } = useDebugLogging();
    const {
            colors,
            widgets,
            getArrayValues,
            getError,
            hasBeenTouched,
            initialValues,
            arrayAppend,
            arrayInsert,
            arrayRemove,
            swapArrayElements
        } = helpers,
        rows = getArrayValues(sectionId);
    // log("REPEATING SECTION", { sectionId, rows, labelSingular, sectionFields, min, max });
    // log("Original init vals:", initialValues);

    if (tabs) {
        // log("REPEATING TABS!");
        const { tabHeaders, panels } = rows.reduce(
            (o, row, index) => {
                // log("Section?", fieldPath, isSection(fieldDef), visible);
                const rowId = dotJoin(sectionId, index);
                const isLastRow = index === rows.length - 1;

                // const value = getValues(fieldPath),
                //     error = ensureArray(getError(fieldPath))[0],
                //     isTouched = hasBeenTouched(fieldPath);
                // log("REPEATING TAB", rowId);
                o.tabHeaders.push(
                    <Tab key={rowId}>
                        <widgets.label helpers={helpers}>
                            {rows.length > min && (
                                <Removerow
                                    className="array remove"
                                    onClick={() => arrayRemove(sectionId, index)} // remove row from the list
                                >
                                    -
                                </Removerow>
                            )}
                            {`${labelSingular} #${index + 1}`}{" "}
                            {!is.number(max) || rows.length < max ? (
                                <Newrow
                                    className={classNames(["array", isLastRow ? "append" : "insert"])}
                                    onClick={
                                        isLastRow ? () => arrayAppend(sectionId) : () => arrayInsert(sectionId, index)
                                    } // insert an empty object at a position
                                    color={colors.themePrimary}
                                >
                                    +
                                </Newrow>
                            ) : null}
                        </widgets.label>
                    </Tab>
                );
                o.panels.push(
                    <TabPanel key={rowId} className="repeating-section">
                        <div className="repeating" id={rowId} key={rowId}>
                            {o2t(sectionFields).map(([fieldName, fieldDef]) => {
                                const fieldPath = dotJoin(sectionId, index, fieldName);
                                const value = row[fieldName],
                                    error = ensureArray(getError(fieldPath))[0],
                                    isTouched = hasBeenTouched(fieldPath);
                                return (
                                    <FormulationistField
                                        fieldPath={fieldPath}
                                        error={error}
                                        fieldName={fieldName}
                                        fieldDef={fieldDef}
                                        key={fieldPath}
                                        helpers={helpers}
                                        value={value}
                                        isTouched={isTouched}
                                    />
                                );
                            })}
                        </div>
                    </TabPanel>
                );

                return o;
            },
            { tabHeaders: [], panels: [] }
        );

        return (
            <Tabs>
                <TabList>{tabHeaders}</TabList>
                {panels}
            </Tabs>
        );
    }

    return (
        <div className="repeating-section" name={sectionId}>
            {rows && rows.length > 0 ? (
                rows.map((row, index) => {
                    const rowId = dotJoin(sectionId, index);
                    const isLastRow = index === rows.length - 1,
                        isFirstRow = index === 0;
                    return (
                        <div className="repeating" id={rowId} key={rowId}>
                            <label className="form-label">{`${labelSingular} #${index + 1}`}</label>
                            {o2t(sectionFields).map(([fieldName, fieldDef]) => {
                                const fieldPath = dotJoin(sectionId, index, fieldName);
                                const value = row[fieldName],
                                    error = ensureArray(getError(fieldPath))[0],
                                    isTouched = hasBeenTouched(fieldPath);
                                // log("Array Section row field:", { row, fieldPath, value, error, isTouched });
                                return (
                                    <FormulationistField
                                        fieldPath={fieldPath}
                                        error={error}
                                        fieldName={fieldName}
                                        fieldDef={fieldDef}
                                        key={fieldPath}
                                        helpers={helpers}
                                        value={value}
                                        isTouched={isTouched}
                                    />
                                );
                            })}

                            {rows.length > min && (
                                <Removerow
                                    className="array remove"
                                    onClick={() => arrayRemove(sectionId, index)} // remove row from the list
                                >
                                    -
                                </Removerow>
                            )}
                            {!is.number(max) || rows.length < max ? (
                                <Newrow
                                    className={classNames(["array", isLastRow ? "append" : "insert"])}
                                    onClick={
                                        isLastRow ? () => arrayAppend(sectionId) : () => arrayInsert(sectionId, index)
                                    } // insert an empty object at a position
                                    color={colors.themePrimary}
                                >
                                    +
                                </Newrow>
                            ) : null}
                            <aside className="array shift">
                                {!isFirstRow && (
                                    <ShiftUp
                                        className="shift up"
                                        onClick={() => swapArrayElements(sectionId, index, index - 1)}
                                    />
                                )}
                                {!isLastRow && (
                                    <ShiftDown
                                        className="shift down"
                                        onClick={() => swapArrayElements(sectionId, index, index + 1)}
                                    />
                                )}
                            </aside>
                        </div>
                    );
                })
            ) : (
                <Newrow
                    className="array append"
                    onClick={() => arrayAppend(sectionId)} // insert an empty object
                    color={colors.themePrimary}
                >
                    +
                </Newrow>
            )}
        </div>
    );
};
