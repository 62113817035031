import is from "@sindresorhus/is";

export const processOptions = (options, imageSizeObj) =>
    options.filter(Boolean).map((x, i) => {
        if (is.object(x)) {
            const { key = i, text, image: imageSrc, imageSelected: selectedImageSrc = imageSrc } = x;
            const imageSize = imageSrc ? imageSizeObj || { width: 32, height: 32 } : undefined;
            return { key, text, imageSrc, selectedImageSrc, imageSize };
        } else return { key: x, text: x };
    });
