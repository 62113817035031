import { doArraysIntersect } from "./formDefRuntime";
import evaluate from "./math-with-dates.js";

const evaluateExpressions = (expressionsList, data, whatChanged) => {
    // console.log("evaluateExpressions:", expressionsList, data, whatChanged);
    const results = expressionsList.reduce((o, { key, expr, dependsOn }) => {
        // dependsOn.length === 0 should be augmented with a check for a value either in the formDef (which we don't have here) or by memoisation
        if (dependsOn.length === 0 || doArraysIntersect(dependsOn, whatChanged)) o[key] = evaluate(expr, data);
        return o;
    }, {});
    return results;
};

export default evaluateExpressions;
