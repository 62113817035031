import React from "react";
import { Overlay, Popover } from "react-bootstrap";

const PopoverBubble = ({ targetRef, id = "info-bubble", children }) => (
    <Overlay container={targetRef} target={targetRef} show={true}>
        <Popover id={id}>
            <Popover.Body>{children}</Popover.Body>
        </Popover>
    </Overlay>
);

export default PopoverBubble;
