import React, { useCallback, useMemo, useEffect, useState } from "react";
import is from "@sindresorhus/is";
import { Stepper } from "react-form-stepper";
import useVisited from "./useVisited";
import {
    FormHealthIndicator,
    SectionHeader,
    SubSection,
    ErrorList,
    findFirstField
} from "@jfdi/formulationist-core-form-common";
import { useFormRuntime, cap1st, t2o, o2t, useDebugLogging } from "@jfdi/formulationist-core";

const DefaultLabels = { submit: "Finished", next: "Next", previous: "Back", cancel: "Cancel" };

// TODO replace the awful, heavyweight stepper component with our own, and implement onClick
export const FormMultiPage = ({
    compiledForm,
    onSubmit,
    onReset,
    onRevert,
    onChange,
    lookups,
    parameterData,
    footerComponent
}) => {
    const { log } = useDebugLogging();
    const { form, errors, helpers, handleSubmit, submitCount, isSubmitting, isLoading, isDirty } = useFormRuntime({
        compiledForm,
        onReset,
        onRevert,
        onChange,
        lookups,
        parameterData
    });
    const FooterComponent = footerComponent;
    const { formMeta } = compiledForm;
    const { options, getFieldDef } = helpers;
    const { buttons: { submit, next, previous, reset, cancel = reset, hide = [] } = {} } = options;
    const shouldShow = x => !hide.includes(x);
    const { pages, widgets, colors } = formMeta,
        pageDefs = pages
            .map(pageId => ({ pageId, ...getFieldDef(pageId) }))
            .filter(({ when, hidden }) => !hidden && (is.nullOrUndefined(when) || when));
    const [pageNum, setPageNum] = useState(0);
    const { wasVisited, setVisited } = useVisited();

    const page = pageDefs[pageNum],
        { pageId } = page,
        isLastPage = pageNum === pageDefs.length - 1;
    const pageErrors = useMemo(() => t2o(o2t(errors).filter(([k]) => k.startsWith(pageId + "."))), [errors, pageId]);
    const pageHasErrors = is.nonEmptyObject(pageErrors),
        formHasErrors = is.nonEmptyObject(errors);
    const disableNext = isSubmitting || isLastPage || pageHasErrors;
    log("PAGE", pageId, page, pageNum, pages, pageErrors, pageHasErrors);

    const handlePrevious = useCallback(() => setPageNum(pageNum - 1), [pageNum]);
    const handleNext = useCallback(() => setPageNum(pageNum + 1), [pageNum]);

    useEffect(() => {
        if (!disableNext) setVisited(pageNum);
    }, [disableNext, pageNum, setVisited]);

    const firstField = useMemo(() => `${pageId}.${findFirstField(page.fields)}`, [page.fields, pageId]);
    helpers.firstField = firstField;

    const submitForm = handleSubmit(onSubmit);

    return isLoading ? null : (
        <>
            <SectionHeader header={form.header} helpers={helpers} />
            <form noValidate className="formulationist multi">
                <widgets.container>
                    <Stepper
                        steps={pageDefs.map(({ fieldName, label, title }) => ({
                            label: label || title || cap1st(fieldName)
                        }))}
                        activeStep={pageNum}
                        styleConfig={{
                            activeBgColor: colors.themePrimary,
                            completedBgColor: colors.themeLighter
                        }}
                    />
                </widgets.container>
                <SubSection
                    sectionId={pageId}
                    sectionDef={page}
                    level={1}
                    errors={errors}
                    key={pageId}
                    helpers={helpers}
                />
                {/* {wasVisited(pageNum) && pageHasErrors && (
                    <ErrorList.Container label="page" errors={pageErrors} parent={pageId} helpers={helpers} />
                )} */}
                {FooterComponent && (
                    <FooterComponent
                        isLastPage={isLastPage}
                        isDirty={isDirty}
                        isValid={!pageHasErrors}
                        isMultiPage={true}
                    />
                )}
                <footer id="buttons">
                    {shouldShow("cancel") && (
                        <widgets.button type="button" id="cancel" onClick={onReset}>
                            {cancel || DefaultLabels.cancel}
                        </widgets.button>
                    )}
                    <widgets.button
                        type="button"
                        id="previous"
                        onClick={handlePrevious}
                        disabled={isSubmitting || pageNum === 0}
                    >
                        {previous || DefaultLabels.previous}
                    </widgets.button>
                    {isLastPage ? (
                        <widgets.button
                            id="submit"
                            type="submit"
                            key="submit"
                            onClick={submitForm}
                            disabled={!isDirty || isSubmitting || formHasErrors}
                        >
                            {submit || DefaultLabels.submit}
                        </widgets.button>
                    ) : (
                        <widgets.button
                            id="next"
                            key="next"
                            disabled={!isLastPage && disableNext}
                            type="button"
                            onClick={handleNext}
                        >
                            {next || DefaultLabels.next}
                        </widgets.button>
                    )}
                    <FormHealthIndicator isError={pageHasErrors} helpers={helpers}>
                        {pageHasErrors && <ErrorList.Container errors={pageErrors} helpers={helpers} />}
                    </FormHealthIndicator>
                </footer>
            </form>
        </>
    );
};
