import is from "@sindresorhus/is";
import extractDynamics from "./extractDynamics";
import extractMetadata from "./extractMetadata";
import { attempt } from "@jfdi/attempt";
import { validateFormdef } from "./validateFormdef";

/*
Compilation is a 2 stage process that transforms a raw formDef into a series of runtime objects:
    1. Extract symbols & expressions that need to be watched & evaluated to make the form dynamic
    2. Extract further metadata lists for runtime optimisation & convenience

Array sections require special treatment because their definitions are more "virtual" than the others.
i.e. at runtime, array elements (an object containing fields) are:
    * created from a copy of the section fields' defaults
    * created and deleted dynamically
    * validated using the validations from a copy of each section field's fieldDef
    * subject to their own section fields' dynamism, e.g. calculations & dynamic validation
*/
export const compile = (rawFormDef, { debug = false } = {}) => {
    console.log(`Formulationist FormDef Compiler v1.9`);
    if (!is.nonEmptyObject(rawFormDef)) return [["Invalid formDef - form definition should be a non-empty object"]];
    else {
        debug && console.log("VALIDATING FORMDEF", rawFormDef);
        const [errors] = attempt(() => validateFormdef(rawFormDef, { strict: true, debug }));
        if (errors) return [errors.errors || [errors.message]];
    }

    const {
        watchlist,
        expressionsList,
        dynamicAttributeSpecs,
        formDef: compiledFormDef
    } = extractDynamics(rawFormDef, { debug });
    debug && console.log("compiledFormDef", compiledFormDef);

    const [processedFormDef, naturalDefaults, defaultValues, pages, validatedFields, arraySections, fieldDefs] =
        extractMetadata(compiledFormDef, { debug });

    processedFormDef.serial = Date.now();

    return [
        undefined, // errors
        {
            formDef: processedFormDef,
            formMeta: {
                options: rawFormDef.options || {},
                watchlist,
                validatedFields,
                arraySections,
                expressionsList,
                dynamicAttributeSpecs,
                pages,
                naturalDefaults,
                defaultValues,
                fieldDefs
            }
        }
    ];
};
