import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { checkFn, classNames } from "./lib/utils";
import is from "@sindresorhus/is";
import { LoggingProvider } from "./DebugLogger";

const hasProps = (o, props) => {
    const keys = Object.keys(o);
    return props.every(p => keys.includes(p));
};
const isValidForm = (f, m) =>
    is.nonEmptyObject(f) &&
    is.nonEmptyObject(m) &&
    f.serial &&
    hasProps(m, ["arraySections", "dynamicAttributeSpecs", "expressionsList", "pages", "validatedFields", "watchlist"]);
const lookupsValid = l =>
    is.function(l) || is.emptyObject(l) || (is.nonEmptyObject(l) && Object.values(l).every(is.array));

const tests = [
    {
        test: ({ FormComponent }) => is.function(FormComponent) && FormComponent.toString().includes("compiledForm"),
        error: "You need to pass an instance of either @jfdi/formulationist-core-form-single or @jfdi/formulationist-core-form-multi into the Formulationist component's formComponent property."
    },
    { test: ({ formDef, formMeta }) => isValidForm(formDef, formMeta), error: "Invalid compiled form" },
    {
        test: ({ lookups }) => lookupsValid(lookups),
        error: "Invalid value supplied for 'lookups' prop. Expected either a callback function, or an object dictionary of arrays containing key/text objects."
    }
];

const runTests = props =>
    tests.every(({ test, error }) => {
        const res = test(props);
        if (!res) throw new Error(error);
        return res;
    });

export const Formulationist = ({
    formId,
    compiledForm = {},
    widgetPack,
    additionalWidgets,
    onSubmit,
    onChange,
    onReset,
    onRevert,
    formComponent: FormComponent,
    lookups = {},
    parameterData = {},
    debug = false,
    footerComponent
}) => {
    const { formDef = {}, formMeta = {} } = compiledForm ?? {};
    const { id, widgets: packWidgets, colors, provider: Outer } = widgetPack;
    const widgets = useMemo(() => ({ ...packWidgets, ...additionalWidgets }), [additionalWidgets, packWidgets]);
    formMeta.widgets = widgets;
    formMeta.colors = colors;
    runTests({ FormComponent, formDef, formMeta, lookups });

    const submitForm = useCallback(
        async values => {
            const fn = checkFn(onSubmit);
            return fn && (await fn(values));
        },
        [onSubmit]
    );

    console.log(`Formulationist Core v1.9`);
    return (
        <Outer>
            <widgets.container className={classNames(["formulationist-container", id])}>
                <LoggingProvider debugMode={debug}>
                    <FormComponent
                        formId={formId}
                        compiledForm={compiledForm}
                        onSubmit={submitForm}
                        onChange={checkFn(onChange)}
                        onReset={checkFn(onReset)}
                        onRevert={onRevert}
                        lookups={lookups}
                        parameterData={parameterData}
                        footerComponent={footerComponent}
                    />
                </LoggingProvider>
            </widgets.container>
        </Outer>
    );
};

Formulationist.propTypes = {
    formId: PropTypes.string,
    compiledForm: PropTypes.object,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onReset: PropTypes.func,
    onRevert: PropTypes.func,
    formComponent: PropTypes.func,
    lookups: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    parameterData: PropTypes.object
};
