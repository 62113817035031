import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import evaluateExpressions from "./evaluateExpressions.js";
import is from "@sindresorhus/is";
import { cap1st, dotJoin, dotSplit, isNestedArray, lastElement, o2t, t2o, zipArrays2Object } from "../lib/utils.js";
import isEqual from "lodash-es/isEqual";
import get from "lodash-es/get";
import set from "lodash-es/set";
import defaultsDeep from "lodash-es/defaultsDeep";
import merge from "lodash-es/merge";
import { defaultErrorMessages } from "../lib/defaultErrors.js";
import { interpolate, doArraysIntersect } from "./formDefRuntime.js";
import cloneDeep from "lodash-es/cloneDeep";
import { useDebugLogging } from "../DebugLogger.js";
import formatISO from "date-fns/formatISO";

// You'll see all sorts of mutation going on here, along with a distinct disdain for React state.
// Don't try to change this. It's by design, for the sake of performance and optimisation.

const regexEmail = /([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}$/i;
const regexUrl = /^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,6}|[\d\.]+)([\/:?=&#]{1}[\da-z\.-]+)*[\/\?]?$/i;

const validationOps = {
    required: (val, opVal, type) => !(is.nullOrUndefined(val) || is.emptyStringOrWhitespace(val)),
    min: (val, opVal, type) =>
        type === "number" ? val >= opVal : type === "string" ? is.string(val) && val.length >= opVal : true,
    max: (val, opVal, type) =>
        type === "number" ? val <= opVal : type === "string" ? is.string(val) && val.length <= opVal : true,
    positive: (val, opVal, type) => (type === "number" ? is.number(val) && val >= 0 : true),
    negative: (val, opVal, type) => (type === "number" ? is.number(val) && val < 0 : true),
    integer: (val, opVal, type) => (type === "number" ? is.number(val) && val === parseInt(val) : true),
    email: (val, opVal, type) => (type === "string" ? is.string(val) && regexEmail.test(val) : true),
    url: (val, opVal, type) => (type === "string" ? is.string(val) && regexUrl.test(val) : true)
};

const dictToHierarchy = dict =>
    Object.entries(dict).reduce((obj, [path, val]) => {
        set(obj, path, val);
        return obj;
    }, {});

const regexSqBrkArrayIndex = /\[(\d+)\]/g,
    regexDotArrayIndex = /\.\d+\./g;
const fixupArrayPath = path => path.replace(regexSqBrkArrayIndex, ".$1");
const stripIndicesFromPath = path => path.replaceAll(regexDotArrayIndex, ".");
const expandFieldPathToFieldDefPath = fieldPath => "fields." + dotSplit(fieldPath).join(".fields.");

const copyFormDef = formDef => cloneDeep(formDef);

const deleteSectionDataFromDict = (obj, sectionPath) =>
    Object.keys(obj)
        .filter(key => key.startsWith(sectionPath + "."))
        .forEach(key => {
            delete obj[key];
        });

const insertObjectIntoDict = (dict, sectionPath, obj) =>
    o2t(obj).forEach(([k, v]) => {
        const sectionItemPath = dotJoin(sectionPath, k);
        dict[sectionItemPath] = v;
    });

const insertSectionItemsIntoDictFromArray = (obj, sectionArray, sectionPath) =>
    sectionArray.forEach((o, i) => {
        insertObjectIntoDict(obj, dotJoin(sectionPath, i), o);
    });

const useFormRuntime = ({ compiledForm, onReset, onRevert, onChange, lookups, parameterData }) => {
    const { log } = useDebugLogging();
    const [isLoading, setLoading] = useState(true);
    const { formMeta, formDef } = compiledForm;
    const {
        expressionsList,
        dynamicAttributeSpecs,
        watchlist,
        validatedFields,
        arraySections,
        naturalDefaults,
        fieldDefs,
        defaultValues,
        widgets,
        colors,
        options
    } = formMeta;

    Object.entries(naturalDefaults).forEach(([k, v], i) => {
        if (is.string(v) && fieldDefs[k].type === "date" && !is.date(v)) {
            naturalDefaults[k] = new Date(v);
        }
    });

    const initialData = useMemo(() => {
        log("Resolving defaults", { parameterData, naturalDefaults });
        const allDefaults = defaultsDeep(parameterData, naturalDefaults);
        const data = merge(
            allDefaults,
            t2o(o2t(defaultValues).filter(([, x]) => typeof x !== "string" || !(x.startsWith("${") && x.endsWith("}"))))
        );
        log("Initial data merge", { data });
        return data;
    }, [defaultValues, log, naturalDefaults, parameterData]);

    const { defaultErrorMessages: formDefaultMessages = {} } = options;

    const formDefCopy = useMemo(() => {
        log("COPY FORMDEF");
        return copyFormDef(formDef);
    }, [formDef, log]);
    const form = useRef(formDefCopy);

    const // stored flat - a simple (dot-path) key/value lookup
        formData = useRef({});
    const formDataH = useRef({});
    const touched = useRef([]);
    const errors = useRef({});
    const helpers = useRef({});
    const submitCallbacks = useRef([]);
    const initialWatchData = useMemo(() => watchlist.map(fieldPath => get(formData.current, fieldPath)), [watchlist]);
    const prevWatchData = useRef(zipArrays2Object(watchlist, initialWatchData)),
        prevEvalData = useRef(t2o(expressionsList.map(({ key }) => [key, undefined])));
    const submitCount = useRef(0);
    const [timestamp, setTimestamp] = useState(Date.now());
    const [isSubmitting, setSubmitting] = useState(false);
    const isDirty = useRef(false);

    useEffect(() => {
        form.current = formDefCopy;
        if (formDef.serial !== form.current.serial) {
            setLoading(true);
            form.current = formDefCopy;
            console.warn("form copy updated due to serial mismatch");
            setLoading(false);
        }
    }, [formDef, formDefCopy]);

    useEffect(() => {
        log("Current Form Data", formData.current);
    });

    const createFormDataFromInitialValues = useCallback(
        (initVals, arraySections = []) => {
            log("createFormDataFromInitialValues", { initVals, arraySections });
            // Should do this in extractMetadata really...
            const arrayInitVals = o2t(cloneDeep(arraySections)).reduce((o, [sectionId, sectionFields]) => {
                const sectionDef = get(formDef, sectionId) ?? {};
                const { default: defaultValue = 1 } = sectionDef;
                o[sectionId] = [];
                const section = o[sectionId];
                for (let i = 1; i <= defaultValue; i++) section.push(sectionFields);
                return o;
            }, {});
            const res = { ...arrayInitVals, ...initVals }; // This order, or we overwrite our actual array data!
            log("Initial Form Data Dictionary:", res);
            return res;
        },
        [formDef, log]
    );

    const allInitialFormData = useMemo(() => {
        log("Computing allInitialFormData");
        return createFormDataFromInitialValues(initialData, arraySections);
    }, [arraySections, createFormDataFromInitialValues, initialData, log]);

    const notifyFormChange = useCallback(
        source => {
            log("FORM UPDATE TRIGGER:", source, form.current);
            setTimestamp(Date.now());
        },
        [log]
    );

    const callOnChange = useCallback(
        // (changed = {}) => {
        () => {
            log("callOnChange");
            // return is.function(onChange) && onChange({ data: formDataH.current, dict: formData.current, ...changed });
            return is.function(onChange) && onChange({ data: formDataH.current, dict: formData.current });
        },
        [log, onChange]
    );

    useEffect(() => {
        log("Change detected in one or more of allInitialFormData, callOnChange, notifyFormChange");
        formData.current = allInitialFormData;
        formDataH.current = dictToHierarchy(formData.current);
        isDirty.current = false;
        callOnChange();
        notifyFormChange("Initial Form Data Changed");
    }, [allInitialFormData, callOnChange, log, notifyFormChange]);

    const getError = useCallback(fieldPath => errors.current[fieldPath], []);
    const getValues = useCallback(fieldPath => (fieldPath ? formData.current[fieldPath] : formData.current), []);
    const getArrayValues = useCallback(sectionId => {
        const vals = get(formDataH.current, sectionId);
        if (is.array(vals)) return vals;
        // else throw new Error("Array section is not an array - Inconsistent Form Data");
    }, []);
    const getFieldAttr = useCallback(
        (fieldPath, attr) => get(form.current, dotJoin(expandFieldPathToFieldDefPath(fieldPath), attr)),
        []
    );
    const getFieldDef = useCallback(fieldPath => {
        const fullPath = expandFieldPathToFieldDefPath(fieldPath),
            fieldDef = get(form.current, fullPath);
        return fieldDef;
    }, []);

    const getFieldLabel = useCallback(
        fieldPath => {
            const fieldDef = getFieldDef(stripIndicesFromPath(fieldPath)) ?? {};
            const { label, title } = fieldDef;
            const defaultLabel = label ?? title;
            const parts = dotSplit(fieldPath);
            const lastPart = lastElement(parts);
            const index = parseInt(lastPart);
            const hasIndex = is.number(index);

            if (hasIndex) {
                parts.pop();
                const parentPath = dotJoin(...parts);
                const { labelSingular = defaultLabel } = getFieldDef(parentPath) ?? {};

                return labelSingular + ` #${index + 1}`;
            } else return defaultLabel ?? cap1st(fieldPath);
        },
        [getFieldDef]
    );

    const saveDataValue = useCallback(
        (fieldPath, value) => {
            formData.current[fieldPath] = value;
            set(formDataH.current, fieldPath, value);
            // callOnChange({ fieldPath, value });
            callOnChange();
            isDirty.current = true;
            log("Data:", formData.current, "DataH:", formDataH.current);
        },
        [callOnChange, log]
    );

    const saveFormFieldAttribute = useCallback((path, value) => set(form.current, path, value), []);
    const getFormFieldAttribute = useCallback((path, value) => get(form.current, path), []);
    const runDeepValidation = useCallback(
        (sectionDef, sectionName) => {
            // By the time we get here there are no more tokens left in the
            // live formDef, because they've all been replaced with values.
            // Therefore no token substitution needs to happen - if a validation is false,
            // include its corresponding error message.
            const { type, fields: sectionFields } = sectionDef;
            const isArraySection = type === "array";
            isArraySection && log("Array Section", sectionName, sectionFields);
            const whatToValidate = o2t(sectionFields);
            log("whatToValidate", sectionName ?? "root", whatToValidate, isArraySection);

            const validateSection = i =>
                whatToValidate.reduce((collectedErrors, [fieldName, fieldDef]) => {
                    const { fields, type, validations, when } = fieldDef;
                    const fieldPath = dotJoin(sectionName, fieldName, i);

                    const isUnconditionalOrIsVisible = is.undefined(when) || when;
                    if (fields && isUnconditionalOrIsVisible)
                        collectedErrors = { ...collectedErrors, ...runDeepValidation(fieldDef, fieldPath) };
                    if (validations && isUnconditionalOrIsVisible) {
                        const res = validations.reduce((errs, [op, args]) => {
                            if (isNestedArray(args)) {
                                const errsFromNestedValids = args.reduce(
                                    (a, [v, m = formDefaultMessages[op] ?? defaultErrorMessages[op]]) => {
                                        if (!v) a.push(m);
                                        return a;
                                    },
                                    []
                                );
                                errs.push(...errsFromNestedValids);
                            } else {
                                const [opVal, msg = formDefaultMessages[op] ?? defaultErrorMessages[op]] = args;
                                const value = formData.current[fieldPath];
                                const opFn = validationOps[op];
                                const valid = is.function(opFn) ? opFn(value, opVal, type) : opVal;
                                // log("VALID?", fieldName, op, opVal, resolvedOpVal, valid);
                                if (!valid) errs.push(msg);
                            }
                            return errs;
                        }, []);

                        if (res.length) collectedErrors[fieldPath] = res;
                    }
                    // when !== undefined && (o[fieldPath].when = when);
                    return collectedErrors;
                }, {});
            // log("GETTING", formDataH.current, sectionName);
            const isValid = isArraySection
                ? (get(formDataH.current, sectionName) ?? []).every((rec, i) => validateSection(i))
                : validateSection();

            return isValid;
        },
        [formDefaultMessages, log]
    );

    const processDynamics = useCallback(
        (whatChanged = []) => {
            let somethingChanged = false;

            // recalculate all dynamics
            const results = evaluateExpressions(expressionsList, formDataH.current, whatChanged);
            log("expression results:", results);
            prevEvalData.current = { ...prevEvalData.current, ...results };

            const allCurrentData = { ...formDataH.current, ...results };
            dynamicAttributeSpecs.forEach(({ attr, fieldPath, path, spec, dependsOn }) => {
                if (dependsOn.length === 0 || doArraysIntersect(dependsOn, whatChanged)) {
                    const fixedUpPath = fixupArrayPath(path);
                    const value = interpolate(spec, allCurrentData),
                        oldValue = getFormFieldAttribute(fixedUpPath);

                    if (!isEqual(value, oldValue)) {
                        if (["calc", "default"].includes(attr)) saveDataValue(fieldPath, value);

                        // log("Setting form value:", fixedUpPath, "=", spec, "(", value, ")");
                        saveFormFieldAttribute(fixedUpPath, value);

                        log("SOMETHING CHANGED: FORM DATA", value, "!==", oldValue, "Path:", fieldPath);
                        somethingChanged = true;
                    }
                }
                // else log("Not updating:", path, dependsOn.length, doArraysIntersect(dependsOn, [fieldPath]));
            });

            // validation
            const oldErrors = cloneDeep(errors.current);
            errors.current = runDeepValidation(form.current);
            if (!isEqual(errors.current, oldErrors)) {
                log("SOMETHING CHANGED: ERRORS", errors.current, "!==", oldErrors);
                somethingChanged = true;
            }
            log("ALL FORM VALIDITY", errors.current);

            if (isLoading) setLoading(false);
            return somethingChanged;
        },
        [
            dynamicAttributeSpecs,
            expressionsList,
            getFormFieldAttribute,
            isLoading,
            log,
            runDeepValidation,
            saveDataValue,
            saveFormFieldAttribute
        ]
    );

    useEffect(() => {
        if (processDynamics(watchlist)) notifyFormChange("useEffect[notifyFormChange, processDynamics, watchlist]");
    }, [notifyFormChange, processDynamics, watchlist]);

    const hasBeenTouched = useCallback(fieldPath => touched.current.includes(fieldPath), []);
    const setTouched = useCallback(
        fieldPath => {
            if (!touched.current.includes(fieldPath)) {
                touched.current.push(fieldPath);
                notifyFormChange("setTouched->useCallback[notifyFormChange]");
            }
        },
        [notifyFormChange]
    );

    const dataChanged = useCallback(
        (fieldPath, currentValue) =>
            validatedFields.includes(fieldPath) ||
            (watchlist.includes(fieldPath) && !isEqual(prevWatchData.current[fieldPath], currentValue)),
        [validatedFields, watchlist]
    );

    // perhaps optimise by queuing updates for processing once a 200ms idle period has passed
    const setValue = useCallback(
        (fieldPath, value) => {
            setTouched(fieldPath);
            // log("Setting value:", fieldPath, value);
            saveDataValue(fieldPath, value);

            if (dataChanged(fieldPath, value)) {
                prevWatchData.current[fieldPath] = value;
                processDynamics([fieldPath]);
            }

            notifyFormChange(
                "setValue->useCallback[dataChanged, notifyFormChange, processDynamics, saveDataValue, setTouched]"
            );
        },
        [dataChanged, notifyFormChange, processDynamics, saveDataValue, setTouched]
    );

    const dirtySection = useCallback(
        sectionPath => {
            isDirty.current = true;
            setTouched(sectionPath);
            notifyFormChange();
            callOnChange();
        },
        [callOnChange, notifyFormChange, setTouched]
    );

    const arrayAppend = useCallback(
        sectionPath => {
            const newRec = { ...arraySections[sectionPath] };
            log("Array Append", newRec);
            const ary = get(formDataH.current, sectionPath);
            log("Array data", { ary, newRec });
            if (is.array(ary)) {
                ary.push(newRec); // stick the new one on the end of the array - and for formDataH, that's it - done
                const index = ary.length - 1;
                // but for the flat formData, we need to insert the new key/value pairs
                insertObjectIntoDict(formData.current, dotJoin(sectionPath, index), newRec);
                dirtySection(sectionPath);
            } else throw new Error("Repeating section isn't an array - inconsistent form metadata");
        },
        [arraySections, dirtySection, log]
    );

    const arrayInsert = useCallback(
        (sectionPath, index) => {
            const newRec = { ...arraySections[sectionPath] };
            log("Array Insert", newRec);
            log("Array sections:", { arraySections });
            const ary = get(formDataH.current, sectionPath);
            log("Array data", { ary, newRec });
            if (is.array(ary)) {
                ary.splice(index, 0, newRec); // stick the new one into the array at index - and for formDataH, that's it - done
                // but for the flat formData, we need to do a ripple-edit, shuffling all the data up
                insertObjectIntoDict(formData.current, dotJoin(sectionPath, index), newRec);
                dirtySection(sectionPath);
            } else throw new Error("Repeating section isn't an array - inconsistent form metadata");
        },
        [arraySections, dirtySection, log]
    );

    const arrayRemove = useCallback(
        (sectionPath, index) => {
            log("Array Delete");
            const ary = get(formDataH.current, sectionPath);
            if (is.array(ary)) {
                if (index <= ary.length - 1) {
                    ary.splice(index, 1);
                    // so that's the array element deleted... but deleting it from the flat dictionary is a lot harder,
                    // due to fixed array indices in the object keys... easier to delete everything for the section,
                    // then add them back
                    deleteSectionDataFromDict(formData.current, sectionPath);
                    insertSectionItemsIntoDictFromArray(formData.current, ary, sectionPath);
                    dirtySection(sectionPath);
                } else throw new Error("Repeating section array shorter than expected - inconsistent form metadata");
            } else throw new Error("Repeating section isn't an array - inconsistent form metadata");
        },
        [dirtySection, log]
    );

    const swapArrayElements = useCallback(
        (sectionPath, index1, index2) => {
            log("Array Shuffle");
            const ary = get(formDataH.current, sectionPath);
            if (is.array(ary)) {
                if (index1 <= ary.length - 1 && index2 <= ary.length - 1) {
                    // swap the array elements
                    const tempObj = { ...ary[index1] };
                    ary[index1] = ary[index2];
                    ary[index2] = tempObj;
                    // now update the dictionary to reflect the change
                    deleteSectionDataFromDict(formData.current, sectionPath);
                    insertSectionItemsIntoDictFromArray(formData.current, ary, sectionPath);
                    dirtySection(sectionPath);
                } else throw new Error("Repeating section array shorter than expected - inconsistent form metadata");
            } else throw new Error("Repeating section isn't an array - inconsistent form metadata");
        },
        [dirtySection, log]
    );

    const registerSubmitCallback = useCallback(
        cb => {
            if (is.function(cb) && !submitCallbacks.current.includes(cb)) submitCallbacks.current.push(cb);
            log("Registered Submit Callback", submitCallbacks.current.length);
        },
        [log]
    );

    helpers.current.widgets = widgets;
    helpers.current.colors = colors;
    helpers.current.options = options;
    helpers.current.setTouched = setTouched;
    helpers.current.hasBeenTouched = hasBeenTouched;
    helpers.current.setValue = setValue;
    helpers.current.getValues = getValues;
    helpers.current.getArrayValues = getArrayValues;
    helpers.current.getFieldAttr = getFieldAttr;
    helpers.current.getFieldLabel = getFieldLabel;
    helpers.current.getFieldDef = getFieldDef;
    helpers.current.getError = getError;
    helpers.current.initialValues = initialData;
    helpers.current.arrayAppend = arrayAppend;
    helpers.current.arrayInsert = arrayInsert;
    helpers.current.arrayRemove = arrayRemove;
    helpers.current.swapArrayElements = swapArrayElements;
    helpers.current.registerSubmitCallback = registerSubmitCallback;
    helpers.current.lookups = lookups;
    helpers.current.formatISO = formatISO;

    const doSubmit = useCallback(() => {}, []);
    const handleSubmit = useCallback(onSubmit => {
        if (!is.function(onSubmit)) throw new Error("onSubmit should be a function");

        return e => {
            submitCallbacks.current.forEach(cb => cb(formData.current));

            setSubmitting(true);
            submitCount.current++;
            onSubmit(formDataH.current, submitCount.current);
            setSubmitting(false);

            e.preventDefault();
        };
    }, []);

    const clearForm = useCallback(
        (newData, description) => {
            log(description);
            formData.current = newData;
            formDataH.current = dictToHierarchy(formData.current);
            isDirty.current = false;
            touched.current = [];
            submitCount.current = 0;
            processDynamics();
            notifyFormChange(description);
            callOnChange();
        },
        [callOnChange, log, notifyFormChange, processDynamics]
    );

    const doReset = useCallback(() => clearForm({}, "RESET"), [clearForm]);

    const reset = useCallback(() => {
        if (isDirty.current && is.function(onReset))
            onReset({
                callback: doReset,
                dict: formData.current,
                data: formDataH.current
            });
        else doReset();
    }, [doReset, onReset]);

    const doRevert = useCallback(
        () => clearForm(cloneDeep(allInitialFormData), "REVERT"),
        [allInitialFormData, clearForm]
    );

    const revert = useCallback(() => {
        if (isDirty.current && is.function(onRevert))
            onRevert({
                callback: doRevert,
                dict: formData.current,
                data: formDataH.current
            });
        else doRevert();
    }, [doRevert, onRevert]);

    return {
        form: form.current,
        formData: formData.current,
        errors: errors.current,
        handleSubmit,
        submitCount: submitCount.current,
        isSubmitting,
        reset,
        revert,
        helpers: helpers.current,
        timestamp,
        isLoading,
        isDirty: touched.current.length > 0 && isDirty.current
    };
};

export default useFormRuntime;
