/* eslint-disable no-template-curly-in-string */
import is from "@sindresorhus/is";
import * as yup from "yup";
import { dataTypes, o2t } from "./utils";

const makeValidator = strict => {
    const isValidValidationTuple = ([x, y] = []) => (is.boolean(x) || is.number(x) || is.string(x)) && is.string(y);
    const validValidationSpec = (value, meta) =>
        (meta.path || "").endsWith(".valid")
            ? yup.array()
            : is.undefined(value)
            ? yup.mixed()
            : is.string(value)
            ? yup.string()
            : is.number(value)
            ? yup.number()
            : is.boolean(value)
            ? yup.bool()
            : yup.array().length(2).test("validTuple", "${path} not a valid validation tuple", isValidValidationTuple);
    const validationShape = yup.lazy(validValidationSpec);
    const fieldShape = {
        type: yup.string().oneOf(dataTypes),
        title: yup.string(),
        label: yup.string(),
        labelSingular: yup.string(),
        description: yup.string(),
        placeholder: yup.string(),
        calc: yup.string(),
        when: yup.string(),
        default: yup.mixed(),
        control: yup.lazy(val =>
            is.array(val)
                ? yup
                      .array()
                      .length(2)
                      .test(([one, two]) => is.string(one) && is.object(two))
                : yup.string()
        ),
        enum: yup.lazy(val =>
            is.string(val)
                ? yup.string()
                : is.array(val) && val.every(is.object)
                ? yup.array().of(yup.object().shape({ key: yup.mixed(), text: yup.string() }))
                : yup.array().of(yup.string())
        ),
        imageSize: yup.object().shape({ width: yup.number(), height: yup.number() }),
        twelfths: yup.number().strict().min(1).max(12),
        required: validationShape,
        valid: validationShape,
        matches: validationShape,
        pattern: validationShape,
        min: validationShape,
        max: validationShape,
        positive: validationShape,
        integer: validationShape,
        email: validationShape,
        url: validationShape,
        uppercase: yup.bool(),
        disabled: yup.bool(),
        hidden: yup.bool(),
        readOnly: yup.bool(),
        omit: yup.bool(),
        rows: yup.number(),
        inline: yup.bool(),
        prefix: yup.string(),
        suffix: yup.string(),
        autocomplete: yup.string(),
        accept: yup.string(),
        multiple: yup.bool(),
        tabs: yup.bool()
    };
    const header = yup.lazy(value =>
        is.object(value) ? yup.object().shape({ title: yup.string(), description: yup.string() }) : yup.string()
    );
    const makeObjectShape = shape => yup.object().strict(strict).noUnknown(strict).shape(shape);
    const field = makeObjectShape(fieldShape);
    const makeFieldsValidator = value => {
        // console.log("Fields Validator", { value });
        if (!is.nonEmptyObject(value)) {
            return yup.object().required("fields must be a non-empty object");
        } else {
            const shape = o2t(value).reduce((o, [k, v]) => {
                o[k] = v.fields ? field.shape({ header, fields: makeFieldsValidator(v.fields) }) : field;
                return o;
            }, {});
            return makeObjectShape(shape).required();
        }
    };
    const validator = yup.object().shape({
        options: yup.object().notRequired(),
        header,
        type: yup.string().optional().oneOf(["object"]),
        fields: yup.lazy(makeFieldsValidator)
    });
    return validator;
};

export const validateFormdef = (formdef = {}, { strict = false, quick = false }) => {
    console.log(`Formulationist FormDef Validator v1.9`);
    const validator = makeValidator(strict);
    return validator.validateSync(formdef, { abortEarly: quick });
};
