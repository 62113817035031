import React from "react";
import PropTypes from "prop-types";

const Label = ({ children, htmlFor, className, required = false, helpers }) => {
    const { colors: { errorColor = "#f00" } = {} } = helpers;

    return (
        <label className="label" htmlFor={htmlFor}>
            {children}
            {required && (
                <span className="required-indicator" style={{ color: errorColor }}>
                    *
                </span>
            )}
        </label>
    );
};

Label.propTypes = { className: PropTypes.string, helpers: PropTypes.object.isRequired, required: PropTypes.bool };

export default Label;
