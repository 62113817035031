import React from "react";
import { classNames } from "./lib/utils";
import { FormFields } from "./FormFields";
import { RepeatingSection } from "./RepeatingSection";
import { SectionHeader } from "./SectionHeader";

export const SubSection = ({ sectionId, sectionDef, level = 1, errors = {}, helpers }) => {
    const {
        type,
        twelfths = 12,
        header,
        title,
        description,
        label = sectionId,
        labelSingular = label,
        min,
        max,
        fields: sectionFields,
        when: isVisible = true,
        tabs
    } = sectionDef;
    const sectionIsArray = type === "array";
    const repeatClass = sectionIsArray ? "repeating" : "";
    // log("SECTION", sectionId, sectionDef, title, description, sectionIsArray);
    return isVisible ? (
        <section
            className={classNames(["section-container", repeatClass, `level${level}`])}
            style={{ gridColumn: `span ${twelfths}` }}
        >
            <SectionHeader header={header} title={title} description={description} helpers={helpers} />
            <article id={sectionId} className={`form-section ${sectionId} level${level}`}>
                {sectionIsArray ? (
                    <RepeatingSection
                        sectionId={sectionId}
                        labelSingular={labelSingular}
                        sectionFields={sectionFields}
                        helpers={helpers}
                        errors={errors}
                        min={min}
                        max={max}
                        tabs={tabs}
                    />
                ) : (
                    <FormFields tabs={tabs} fields={sectionFields} level={level} errors={errors} helpers={helpers} />
                )}
            </article>
        </section>
    ) : null;
};
