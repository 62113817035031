import is from "@sindresorhus/is";
import { regexDollarBraceToken, regexWholeIsDollarBraceToken } from "../lib/utils";
import get from "lodash-es/get";

export const isFormula = x => x && is.string(x) && x.startsWith("=");

export const interpolate = (templateString = "", params = {}) => {
    const match = regexWholeIsDollarBraceToken.exec(templateString);
    // console.log("Supplied interpolation data:", params);
    // console.log("Interpolation Match:", match);
    return match
        ? get(params, match[1])
        : templateString.replace(regexDollarBraceToken, (_, key) => get(params, key) ?? "");
};

export const doArraysIntersect = (a1, a2) => a1.some(e => a2.includes(e));
