import React, { useRef } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";

const Bubble = ({ hide, toggle, show = false, children, backgroundColor, trigger }) => {
    const buttonRef = useRef();

    return (
        <Popover
            isOpen={show}
            positions={["top", "bottom", "left", "right"]} // preferred positions by priority
            ref={buttonRef}
            padding={0}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={"#888"}
                    arrowSize={10}
                    className="popover-arrow-container"
                    arrowClassName="popover-arrow"
                >
                    <div style={{ backgroundColor }} onClick={hide}>
                        {children}
                    </div>
                </ArrowContainer>
            )}
        >
            {trigger}
            {/* <button type="button" onClick={toggle}>
                Wee!
            </button> */}
        </Popover>
    );
};

export default Bubble;
