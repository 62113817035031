import is from "@sindresorhus/is";

export const cap1st = s => (s ?? "").charAt(0).toUpperCase() + (s ?? "").slice(1);
export const randId = () => "_" + Math.random().toString(36).substr(2, 9);
export const classNames = ary => ary.filter(Boolean).join(" ");
export const o2t = Object.entries,
    t2o = Object.fromEntries;
export const getType = ({ fields, type }) => type || (fields ? "object" : "string");
export const lastElement = a => a[a.length - 1];
export const isNestedArray = (a, all = false) => a[all ? "every" : "some"](is.array);

export const fieldAttrs = [
        "title",
        "description",
        "placeholder",
        "min",
        "max",
        "twelfths",
        "hidden",
        "required",
        "enum",
        "control",
        "rows",
        "inline",
        "when",
        "prefix",
        "suffix",
        "uppercase",
        "imageSize",
        "accept",
        "multiple"
    ],
    availableValidations = [
        "required",
        "min",
        "max",
        "positive",
        "negative",
        "integer",
        "email",
        "url",
        "pattern",
        "valid"
    ];

export const checkFn = fn => (is.function(fn) ? fn : null);
export const isFormula = x => x && is.string(x) && x.startsWith("=");
export const isFieldAttr = x => fieldAttrs.includes(x);
export const isValidation = x => availableValidations.includes(x);
export const ensureArray = x => (is.array(x) ? x : [x]);
export const dotJoin = (...args) => args.filter(x => is.nonEmptyString(x) || is.number(x)).join(".");
export const dotSplit = str => str.split(".");
export const isSection = fieldDef =>
    Boolean(
        ["object", "array"].includes(fieldDef.type ?? "object") &&
            "fields" in fieldDef &&
            fieldDef.fields &&
            (is.array(fieldDef.fields) || is.object(fieldDef.fields))
    );

export const extractSimpleToken = x => x.slice(2, -1); // get token from "${token}"
export const regexPercentBracketedToken = /%([^%]*)%/g;
export const regexWholeIsDollarBraceToken = /^\$\{([^}]*)\}$/;
export const regexDollarBraceToken = /\$\{([^}]*)\}/gm;

const regexpRegexp = /^\/(.+)\/([igmsuy]*)$/;
export const textToRegexp = regexpStr => {
    const match = regexpStr.match(regexpRegexp);
    const [, regexp = regexpStr, options] = match || [];
    return new RegExp(regexp, options);
};

export const zipArrays2Object = (keys = [], values = []) =>
    keys.reduce((o, key, i) => {
        o[key] = values[i];
        return o;
    }, {});

export const dedupeArray = arr => {
    let uniq = [];
    for (const val of arr) {
        uniq[val] = 0;
    }
    return Object.keys(uniq);
};

export const findFirstField = section => {
    for (const t of o2t(section)) {
        const [k, o] = t;
        if (isSection(o)) {
            const f = findFirstField(o.fields);
            if (f) return `${k}.${f}`;
        } else if (!o.hidden) return k;
    }
};
