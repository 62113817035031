import React from "react";
import { Button } from "react-bootstrap";

const variants = {
    button: "secondary",
    submit: "primary",
    reset: "danger",
    revert: "warning"
};

const BS5Button = ({ type = "button", disabled = false, children, onClick, style, className }) => (
    <Button
        type={["submit", "reset"].includes(type) ? type : "button"}
        variant={variants[type]}
        disabled={disabled}
        onClick={onClick}
        className={className}
        style={style}
    >
        {children}
    </Button>
);

export default BS5Button;
