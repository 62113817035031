export const defaultErrorMessages = {
    min: "Too small",
    max: "Too big",
    positive: "Should be >= 0",
    negative: "Should be < 0",
    integer: "Should be a whole number",
    minLength: "Too short",
    maxLength: "Too long",
    minDate: "Date should be later",
    maxDate: "Date should be earlier",
    required: "Mandatory field",
    pattern: "Doesn't look right",
    valid: "Invalid value",
    email: "Not an email address",
    url: "Not a web address"
};
export const validationOpMappings = {
    min: { string: "minLength", date: "minDate" },
    max: { string: "maxLength", date: "maxDate" }
};
