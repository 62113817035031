import is from "@sindresorhus/is";
import React from "react";
import { dotJoin, dotSplit, o2t } from "./lib/utils";
import { ErrorLink } from "./ErrorLink";
import { useDebugLogging } from "@jfdi/formulationist-core";

const getFieldLabelsForHierarchy = (completePath, getFieldLabel) => {
    const { fieldLabels } = dotSplit(completePath).reduce(
        (o, fieldName) => {
            const fieldPath = dotJoin(o.path, fieldName);
            o.path = fieldPath;
            const label = getFieldLabel(fieldPath);
            o.fieldLabels.push(label);
            return o;
        },
        { fieldLabels: [] }
    );
    return fieldLabels;
};

export const ErrorList = ({ errors, helpers, errorColor }) => {
    const { log } = useDebugLogging();
    const { getFieldLabel } = helpers;
    const fieldsAndTheirErrors = o2t(errors ?? {});
    log("ErrorList", errors, fieldsAndTheirErrors);

    return fieldsAndTheirErrors.map(([fieldPath, fieldErrs]) => {
        const fieldLabels = getFieldLabelsForHierarchy(fieldPath, getFieldLabel);
        // log("Field Labels", fieldPath, fieldLabels);
        return fieldErrs.map(message => (
            <ErrorLink
                fieldPath={fieldPath}
                pathLabels={fieldLabels}
                error={message}
                key={fieldPath + message}
                errorColor={errorColor}
            />
        ));
    });
};

const ErrorListContainer = ({ label = "form", children, errors, parent, helpers }) => {
    // const { log } = useDebugLogging();
    const {
        colors: { errorColor = "#f00" }
    } = helpers;
    // log("ErrorListContainer", parent, errors);
    return is.nonEmptyObject(errors) ? (
        <div id="form-errors" style={{ color: errorColor }}>
            <p>Your {label} has errors which must be corrected before submission:</p>
            <ul>
                {children ? (
                    children
                ) : (
                    <ErrorList errors={errors} parent={parent} helpers={helpers} errorColor={errorColor} />
                )}
            </ul>
        </div>
    ) : null;
};

ErrorList.Container = ErrorListContainer;
