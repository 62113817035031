import React from "react";
import is from "@sindresorhus/is";
import { Form } from "react-bootstrap";
import Feedback from "./Feedback";
import { processOptions } from "./utils";

const Select = ({
    label,
    name,
    value,
    options,
    description,
    onBlur,
    onFocus,
    onChange,
    isTouched,
    disabled,
    required,
    isInvalid,
    error,
    helpers,
    autofocus = false
}) => (
    <Form.Group className="form-group select">
        <Form.Label htmlFor={name}>{label}</Form.Label>
        <Form.Select
            name={name}
            className="form-select"
            aria-label={description}
            disabled={disabled}
            isInvalid={isTouched && isInvalid}
            isValid={isTouched && !isInvalid}
            error={error}
            value={value}
            onChange={e => onChange(e.currentTarget.value)}
            onBlur={onBlur}
            onFocus={onFocus}
            autoFocus={autofocus}
        >
            <option key={`${name}-blank`}></option>
            {options.map(x => {
                const option = is.object(x) ? x : { key: x, text: x };
                const { key, text } = option;
                return (
                    <option value={key} key={`${name}-${key}`}>
                        {text}
                    </option>
                );
            })}
        </Form.Select>
        <Feedback error={error} fieldPath={name} title={label} />
    </Form.Group>
);

export default Select;
