import React from "react";
import { Form } from "react-bootstrap";

const FCheckbox = ({
    name,
    label,
    disabled,
    hidden,
    twelfths,
    control: component,
    helpers,
    onBlur,
    onFocus,
    onChange,
    value,
    error,
    isTouched,
    isInvalid,
    autofocus = false
}) => (
    <Form.Group className="field-group checkbox">
        <Form.Label>&nbsp;</Form.Label>
        <Form.Group className="field-group">
            <Form.Check
                type="checkbox"
                disabled={disabled}
                id={name}
                label={label}
                checked={value}
                onChange={e => onChange(e.currentTarget.checked)}
                autoFocus={autofocus}
            />
        </Form.Group>
    </Form.Group>
);

export default FCheckbox;
