import React from "react";
import removeMd from "remove-markdown";

const smartTextStub = ({
    text = "",
    helpers: { data = {}, options: { prefixes: { markdown: prefix = "md~" } = {} } = {}, widgets } = {}
}) => {
    if (text) {
        const isMarkdown = text.startsWith(prefix);
        const textToRender = isMarkdown ? text.slice(prefix.length) : text;
        const Outer = widgets.text || "div";
        return (
            <Outer className="text-outer">
                {isMarkdown ? removeMd(textToRender) : textToRender}
                {isMarkdown && (
                    <sup>
                        <em>md</em>
                    </sup>
                )}
            </Outer>
        );
    } else return null;
};

export default smartTextStub;
